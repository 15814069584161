import BasicLink from './BasicLink'
import React from 'react'

const SingleLink = function ({ className, menuItem }) {
  const {
    primary: {
      title: { text },
      link,
    },
  } = menuItem

  return <BasicLink link={link} text={text} className={className} />
}

export default SingleLink
