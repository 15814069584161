import React, { useState } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import ResolvingLink from '../ResolvingLink'
import Image from 'gatsby-image'
import MenuGroup from './MenuGroup'

import LanguageSwitcher from './LanguageSwitcher'
import Banner from './Banner'
import CartButton from './CartButton'
import MobileMenu from './MobileMenu'
import AccountMenuItem from './AccountMenuItem'

const Navbar = function Navbar({ pageData }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const navbarData = _.get(pageData, ['prismicNavigationBar', 'data'])
  const leftMenuItems = _.get(pageData, [
    'prismicLeftNavigationMenu',
    'data',
    'body',
  ])
  const rightMenuItems = _.get(pageData, [
    'prismicRightNavigationMenu',
    'data',
    'body',
  ])

  const {
    centered_image: centeredImage,
    banner_text: { text: bannerText },
    button_link: buttonLink,
    button_text: { text: buttonText },
    image_link: imageLink,
    cart_page_link: cartPageLink,
    is_banner_enabled: isBannerEnabled,
  } = navbarData

  const backgroundColor = navbarData['background_color']?.text;

  const imageAltText = centeredImage.alt
  const fixedDesktopImage = _.get(
    centeredImage,
    ['desktopImage', 'childImageSharp', 'fixed'],
    null
  )
  const fixedMobileImage = _.get(
    centeredImage,
    ['mobileImage', 'childImageSharp', 'fixed'],
    null
  )
  return (
    <nav
      id="navbar"
      className="w-full flex flex-col"
      style={{ background: backgroundColor ?? 'rgba(0, 0, 0, 0.9)' }}
    >
      <div id="inner-navbar">
        {isBannerEnabled && (
          <Banner
            text={bannerText}
            buttonText={buttonText}
            buttonLink={buttonLink}
          />
        )}
        <div className="select-none container mx-auto py-4 px-4 hidden md:flex">
          <div className="flex-1 flex items-center justify-between text-white navbar-links">
            <div className="flex space-x-2">
              <LanguageSwitcher />
            </div>
            <MenuGroup menuItemsData={leftMenuItems} />
          </div>
          <div className="flex-1 flex items-center justify-center">
            <ResolvingLink link={imageLink}>
              {fixedDesktopImage != null && (
                <Image
                  className="navbar-logo"
                  fixed={fixedDesktopImage}
                  alt={imageAltText}
                />
              )}
            </ResolvingLink>
          </div>
          <div className="flex-1 flex items-center justify-between text-white">
            <MenuGroup menuItemsData={rightMenuItems} />
            <div className="ml-6 flex space-x-4">
              <AccountMenuItem />
              <CartButton cartPageLink={cartPageLink} />
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center md:hidden">
          <ResolvingLink link={imageLink}>
            {fixedMobileImage != null && (
              <Image
                fixed={fixedMobileImage}
                alt={imageAltText}
                className="navbar-logo"
              />
            )}
          </ResolvingLink>
          <div className="flex flex-row text-white items-center">
            <AccountMenuItem />
            <CartButton className="ml-6" cartPageLink={cartPageLink} />
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              id="navigation-button"
              className="ml-6"
            >
              {!isMobileMenuOpen && (
                <svg
                  id="navigation-hamburger"
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
              {isMobileMenuOpen && (
                <svg
                  id="navigation-close"
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <MobileMenu
          onLinkClick={() => {
            setIsMobileMenuOpen(false)
          }}
          className="mt-2 mb-6"
          menuItemsData={[...leftMenuItems, ...rightMenuItems]}
        />
      )}
    </nav>
  )
}

export const query = graphql`
  fragment NavigationBarFragment on PrismicNavigationBar {
    data {
      banner_text {
        text
      }
      button_link {
        url
        target
        link_type
      }
      button_text {
        text
      }
      background_color {
        text
      }
      image_link {
        url
        link_type
        target
      }
      cart_page_link {
        url
        link_type
        target
      }
      is_banner_enabled
      centered_image {
        alt
        desktopImage: localFile {
          childImageSharp {
            fixed(height: 80) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        mobileImage: localFile {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }

  fragment LeftNavigationMenuFragment on PrismicLeftNavigationMenu {
    data {
      body {
        ... on PrismicLeftNavigationMenuBodySingleLink {
          id
          primary {
            link {
              url
              link_type
              target
            }
            title {
              text
            }
          }
          slice_type
        }
        ... on PrismicLeftNavigationMenuBodyLinkMenu {
          id
          primary {
            title {
              text
            }
          }
          slice_type
          items {
            link {
              url
              link_type
              target
            }
            title {
              text
            }
          }
        }
      }
    }
  }

  fragment RightNavigationMenuFragment on PrismicRightNavigationMenu {
    data {
      body {
        ... on PrismicRightNavigationMenuBodySingleLink {
          id
          slice_type
          primary {
            title {
              text
            }
            link {
              url
              target
              link_type
            }
          }
        }
        ... on PrismicRightNavigationMenuBodyLinkMenu {
          id
          slice_type
          primary {
            title {
              text
            }
          }
          items {
            link {
              url
              target
              link_type
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`

export default Navbar
