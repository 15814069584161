import { Elements } from 'prismic-reactjs'
import React from 'react'
import linkResolver from './linkResolver'
import ResolvingLink from '../components/ResolvingLink'

const spanHtmlSerializer = (type, element, content, children, key) => {
  switch (type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      return React.createElement('span', { key }, children)
    case Elements.hyperlink:
      const linkProp = {
        link_type: element.data.link_type,
        target: element.data.target,
        url: element.data.url || linkResolver(element.data),
      }

      return (
        <ResolvingLink key={key} className="underline" link={linkProp}>
          {children}
        </ResolvingLink>
      )

    default:
      return null
  }
}

export default spanHtmlSerializer
