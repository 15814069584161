import React, { useContext } from 'react'
import clsx from 'clsx'
import ResolvingLink from '../ResolvingLink'
import StoreContext from '../../context/StoreContext'

export default function CartButton({ className, cartPageLink }) {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const isItemsExist =
    checkout?.lineItems == null ? false : checkout.lineItems.length !== 0

  return (
    <ResolvingLink link={cartPageLink} className={clsx('relative', className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.25 6.75v.951a1.5 1.5 0 11-1.5 0V6.75h-7.5v.951a1.5 1.5 0 11-1.5 0V6.75H4.53a1.016 1.016 0 00-.41.11 1.91 1.91 0 00-.52.385c-.17.174-.313.375-.424.593-.09.176-.152.365-.182.56l-.746 12.69a1.222 1.222 0 01-.007.083c-.002.013-.002.026-.002.039a.74.74 0 00.092.343c.095.18.223.341.376.476.17.152.36.274.57.362.152.068.317.105.484.109h16.527c.163-.004.322-.04.47-.107.2-.088.384-.21.546-.36.15-.137.274-.298.365-.48a.768.768 0 00.092-.35c0-.014 0-.027-.002-.038-.002-.026-.005-.052-.005-.077l-.747-12.68a.716.716 0 00-.04-.137 1.49 1.49 0 00-.147-.269 2.883 2.883 0 00-.606-.63 3.358 3.358 0 00-.817-.474 2.37 2.37 0 00-.377-.116 1.231 1.231 0 00-.276-.032H17.25zm0-1.5V3c0-1.945-.849-3-2.587-3H9.348C7.58 0 6.75 1.182 6.75 3.096V5.25H4.5c-1.458.03-2.819 1.51-3 3L.75 21c-.174 1.52 1.507 3 3 3h16.5c1.535.028 3.166-1.505 3-3L22.5 8.25c-.187-1.5-2.209-3-3.75-3h-1.5zm-9 0V3.092c0-.169.01-.336.028-.503.017-.135.043-.268.079-.399.04-.156.109-.302.206-.43a.593.593 0 01.223-.166c.068-.028.139-.05.212-.064.114-.02.229-.03.343-.03h5.329c.12 0 .238.01.356.028.077.013.152.034.225.064.079.03.15.079.208.142.087.111.149.237.184.373.036.126.062.254.077.383.02.169.03.336.03.504V5.25h-7.5z"
          fill="currentColor"
        />
      </svg>
      {isItemsExist && (
        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-grizzli-yellow opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-grizzli-yellow" />
        </span>
      )}
    </ResolvingLink>
  )
}
