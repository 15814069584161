import React from 'react'
import clsx from 'clsx'
import ResolvingLink from '../ResolvingLink'
import _ from 'lodash'

export default function BottomNavbar({ bottomNavbarData, className }) {
  const {
    data: { body: links },
  } = bottomNavbarData

  const renderedLinks =
    links == null
      ? []
      : links.map(link => {
          const { id, slice_type: sliceType } = link
          switch (sliceType) {
            case 'single_link':
              const {
                primary: {
                  title: { text: linkTitle },
                  link: linkInfo,
                },
              } = link

              if (linkTitle == null || linkTitle === '') {
                return null
              }

              return (
                <ResolvingLink key={id} link={linkInfo} className="uppercase">
                  {linkTitle}
                </ResolvingLink>
              )

            default:
              return null
          }
        })

  return (
    <div className={clsx('w-full bg-black', className)}>
      <div className="container mx-auto flex flex-col">
        <div className="flex flex-col items-center space-y-8 md:flex-row md:items-start md:space-y-0 justify-between text-white py-6 px-2">
          <span>®2020 GRIZZLI WINERY</span>
          {_.compact(renderedLinks)}
        </div>
      </div>
    </div>
  )
}
