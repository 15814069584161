import React, { useRef, useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import useOutsideAlerter from '../../utils/useOutsideAlerter'
import BasicLink from './BasicLink'
import SingleLink from './SingleLink'



const LinkMenu = function ({ className, menuItem }) {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => {
    setIsOpen(false)
  })

  const {
    primary: {
      title: { text },
    },
    items,
  } = menuItem

  if (text == null || text === '') {
    return null
  }

  if (items == null || items.length === 0) {
    return null
  }

  const renderedLinks = items.map((item, idx) => {
    const {
      link,
      title: { text },
    } = item

    return (
      <BasicLink
        key={idx}
        link={link}
        text={text}
        onClick={() => {
          setIsOpen(false)
        }}
      />
    )
  })

  return (
    <div ref={wrapperRef} className={clsx('relative', className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(
          'link-btn focus:outline-none uppercase',
          isOpen && 'dropdown-link'
        )}
      >
        {text}
      </button>
      <div
        className={clsx('absolute left-0 w-40 dropdown', !isOpen && 'hidden')}
      >
        <div className="p-4 flex flex-col space-y-4">
          {_.compact(renderedLinks)}
        </div>
      </div>
    </div>
  )
}

const MenuGroup = function MenuGroup({ className, menuItemsData = [] }) {
  const renderedMenuItems = menuItemsData.map(menuItem => {
    const { id, slice_type: sliceType } = menuItem

    if (sliceType === 'link_menu') {
      return <LinkMenu key={id} menuItem={menuItem} />
    }

    if (sliceType === 'single_link') {
      return <SingleLink key={id} menuItem={menuItem} />
    }

    return null
  })

  return (
    <div className={clsx('flex uppercase space-x-6 navbar-links', className)}>
      {_.compact(renderedMenuItems)}
    </div>
  )
}

export default MenuGroup
