import React from 'react'
import { Link } from 'gatsby'

const ResolvingLink = function ({ className, children, link, ...otherProps }) {
  if (
    link == null ||
    link.link_type == null ||
    link.link_type === '' ||
    link.url == null ||
    link.url === ''
  ) {
    return (
      <a className={className} href="#" {...otherProps}>
        {children}
      </a>
    )
  }

  const { link_type: linkType, url } = link

  if (linkType === 'Web') {
    const { target } = link

    const targetProps = {}

    if (target === '_blank') {
      targetProps.target = '_blank'
    }

    return (
      <a
        href={url}
        {...targetProps}
        rel="noreferrer"
        className={className}
        {...otherProps}
      >
        {children}
      </a>
    )
  }

  return (
    <Link to={url} className={className} {...otherProps}>
      {children}
    </Link>
  )
}

export default ResolvingLink
