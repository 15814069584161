import React from 'react'
import clsx from 'clsx'
import StayConnected from './StayConnected'
import BottomNavbar from './BottomNavbar'
import { graphql } from 'gatsby'

export default function Footer({ footerData, className }) {
  const {
    prismicStayConnectedSection: stayConnectedData,
    prismicBottomNavigationBar: bottomNavbarData,
  } = footerData

  return (
    <footer className={clsx('flex flex-col', className)}>
      <StayConnected stayConnectedData={stayConnectedData} />
      <BottomNavbar bottomNavbarData={bottomNavbarData} />
    </footer>
  )
}

export const query = graphql`
  fragment StayConnectedSectionFragment on PrismicStayConnectedSection {
    id
    data {
      button_link {
        url
        link_type
        target
      }
      button_text {
        text
      }
      checkbox_text {
        text
      }
      description {
        text
      }
      disclaimer_text {
        text
        raw
      }
      email_field_title {
        text
      }
      facebook_link {
        url
        target
        link_type
      }
      instagram_link {
        url
        target
        link_type
      }
      last_name_field_title {
        text
      }
      name_field {
        text
      }
      newsletter_title {
        text
      }
      sign_up_button_text {
        text
      }
      title {
        text
      }
      twitter_link {
        url
        link_type
        target
      }
      background_image {
        alt
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }

  fragment BottomNavigationBarFragment on PrismicBottomNavigationBar {
    id
    data {
      body {
        ... on PrismicBottomNavigationBarBodySingleLink {
          id
          slice_label
          slice_type
          primary {
            link {
              url
              link_type
              target
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
