import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ResolvingLink from './ResolvingLink'
import clsx from 'clsx'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import spanHtmlSerializer from '../utils/spanHtmlSerializer'
import { useCookies } from 'react-cookie'
import getSecondsSinceExpiration from '../utils/getSecondsSinceExpiration'

const CookieSlices = function CookieBannerPresenter({ slices, onClose }) {
  const renderedSlices = slices.map(slice => {
    const { id, slice_type: sliceType } = slice

    if (sliceType === 'text') {
      const textRich = _.get(slice, 'primary.text.raw')

      if (textRich == null || textRich === '') {
        return null
      }

      return (
        <p key={id} className="text-center text-base">
          <RichText render={textRich} htmlSerializer={spanHtmlSerializer} />
        </p>
      )
    }

    if (sliceType === 'buttons') {
      const buttons = _.get(slice, 'items')
      if (buttons == null || buttons.length === 0) {
        return null
      }

      const renderedButtons = buttons.map((buttonData, idx) => {
        const buttonText = _.get(buttonData, 'button_text.text')
        const buttonLink = _.get(buttonData, 'button_link')
        const isClickClosesBanner = _.get(
          buttonData,
          'clicking_button_closes_banner',
          false
        )

        if (!buttonText) {
          return null
        }

        if (isClickClosesBanner) {
          return (
            <button key={idx} onClick={onClose} className="link-btn">
              {buttonText}
            </button>
          )
        }

        return (
          <ResolvingLink key={idx} link={buttonLink}>
            {buttonText}
          </ResolvingLink>
        )
      })

      return (
        <div
          key={id}
          className="self-center flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8"
        >
          {_.compact(renderedButtons)}
        </div>
      )
    }

    return null
  })

  return _.compact(renderedSlices)
}

export default function ({ className, pageData }) {
  const cookieName = 'grizzli-gatsby-accepted-cookies'
  const [cookies, setCookie] = useCookies([cookieName])
  const [hasSeenCookie, setHasSeenCookie] = useState(true)

  const cookieBannerSlices = _.get(
    pageData,
    'prismicCookieBanner.data.body',
    []
  )

  if (cookieBannerSlices == null || cookieBannerSlices.length === 0) {
    return null
  }

  const isAccepted = _.get(cookies, cookieName)

  useEffect(() => {
    if (isAccepted !== 'true') {
      setCookie(cookieName, true, {
        path: '/',
        expires: new Date(
          Date.now() + getSecondsSinceExpiration({ years: 1 }) * 1000
        ),
      })
      setHasSeenCookie(false)
    }
  }, [isAccepted, setCookie])

  return (
    !hasSeenCookie && (
      <div className={clsx('cookie-banner', className)}>
        <section className="container mx-auto flex flex-col space-y-4 text-white p-4">
          <CookieSlices
            slices={cookieBannerSlices}
            onClose={() => {
              setHasSeenCookie(true)
            }}
          />
        </section>
      </div>
    )
  )
}

export const query = graphql`
  fragment CookieBannerFragment on PrismicCookieBanner {
    data {
      body {
        ... on PrismicCookieBannerBodyText {
          id
          slice_type
          slice_label
          primary {
            text {
              raw
            }
          }
        }
        ... on PrismicCookieBannerBodyButtons {
          id
          slice_type
          slice_label
          items {
            button_link {
              url
              target
              link_type
            }
            button_text {
              text
            }
            clicking_button_closes_banner
          }
        }
      }
    }
  }
`
