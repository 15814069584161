import ResolvingLink from '../ResolvingLink'
import React from 'react'

const BasicLink = function ({ className, link, text, ...otherProps }) {
  if (link == null || text == null || text === '') {
    return null
  }

  return (
    <ResolvingLink link={link} className={className} {...otherProps}>
      {text}
    </ResolvingLink>
  )
}

export default BasicLink
