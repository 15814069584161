import React from 'react'
import ResolvingLink from '../ResolvingLink'
import clsx from 'clsx'

export default function Banner({ className, text, buttonText, buttonLink }) {
  if (!text) {
    return null
  }

  const isButtonEnabled = buttonText && buttonLink

  return (
    <div className={clsx('banner', className)}>
      <div className="container mx-auto px-4 py-3 flex items-center justify-center">
        <p
          className={clsx(
            'text-white text-base md:w-auto md:text-lg',
            isButtonEnabled && 'w-3/5 text-left',
            !isButtonEnabled && 'text-center'
          )}
        >
          {text}
        </p>
        {buttonText && buttonLink && (
          <ResolvingLink
            link={buttonLink}
            className="ml-4 w-2/5 btn-small btn-white-outline text-sm flex justify-center md:py-2 md:px-12 md:w-auto md:inline-block"
          >
            <span>{buttonText}</span>
          </ResolvingLink>
        )}
      </div>
    </div>
  )
}
