import React from 'react'
import clsx from 'clsx'
import LanguageSwitcher from './LanguageSwitcher'
import _ from 'lodash'
import BasicLink from './BasicLink'

const MobileMenuGroup = ({ menuItemsData, onLinkClick }) => {
  const linkData = menuItemsData.map(menuItem => {
    const { id, slice_type: sliceType } = menuItem

    if (sliceType === 'link_menu') {
      const { items } = menuItem

      if (items == null || items.length === 0) {
        return []
      }

      return items.map(item => {
        const {
          link,
          title: { text },
        } = item

        return {
          text,
          link,
        }
      })
    }

    if (sliceType === 'single_link') {
      const {
        primary: {
          title: { text },
          link,
        },
      } = menuItem

      return {
        id,
        text,
        link,
      }
    }

    return null
  })

  const renderedLinks = _.flatten(_.compact(linkData)).map(
    (singleLinkData, idx) => {
      const { id, text, link } = singleLinkData

      return (
        <BasicLink
          onClick={onLinkClick}
          key={id ?? idx}
          link={link}
          text={text}
        />
      )
    }
  )

  return renderedLinks
}

export default function MobileMenu({
  className,
  onLinkClick,
  menuItemsData = [],
}) {
  return (
    <div
      className={clsx(
        'flex flex-col space-y-6 text-white items-center uppercase navbar-links overflow-y-auto overscroll-none',
        className
      )}
      style={{
        height: '400px',
      }}
    >
      <div className="flex space-x-2">
        <LanguageSwitcher />
      </div>
      <MobileMenuGroup
        menuItemsData={menuItemsData}
        onLinkClick={onLinkClick}
      />
    </div>
  )
}
