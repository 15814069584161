import React from 'react'
import clsx from 'clsx'
import FacebookLogo from '../../images/facebook-white.svg'
import TwitterLogo from '../../images/twitter-white.svg'
import InstagramLogo from '../../images/instagram-white.svg'
import ResolvingLink from '../ResolvingLink'
import { RichText } from 'prismic-reactjs'
import getFluidImg from '../../utils/getFluidImg'
import BackgroundImage from 'gatsby-background-image-es5'
import spanHtmlSerializer from '../../utils/spanHtmlSerializer'
import SubscribeForm from './SubscribeForm'
import SpinnableButton from '../shared/SpinnableButton'

export default function StayConnected({ stayConnectedData, className }) {
  const {
    data: {
      title: { text: title },
      description: { text: description },
      instagram_link: instagramLink,
      facebook_link: facebookLink,
      twitter_link: twitterLink,
      button_text: { text: buttonText },
      button_link: buttonLink,
      newsletter_title: { text: newsletterTitle },
      // First Name Field Title
      name_field: { text: firstNameFieldTitle },
      last_name_field_title: { text: lastNameFieldTitle },
      email_field_title: { text: emailFieldTitle },
      checkbox_text: { text: checkboxText },
      sign_up_button_text: { text: signUpButtonText },
      disclaimer_text: { raw: disclaimerRichText },
      background_image: backgroundImage,
    },
  } = stayConnectedData

  const backgroundFluidImage = getFluidImg(backgroundImage)

  const socialBannerChildren = (
    <>
      <div className="container mx-auto pt-16 pb-16 px-2 flex flex-col items-center md:flex-row md:items-start">
        <div className="flex flex-col items-center md:items-start md:w-2/5 text-white md:pr-10">
          <h1>{title}</h1>
          <p className="mt-8 hidden md:block">{description}</p>
          <div className="flex space-x-16 mt-6">
            <ResolvingLink link={instagramLink}>
              <img className="social-logo" alt="instagram" src={InstagramLogo} />
            </ResolvingLink>
            <ResolvingLink link={facebookLink}>
              <img className="social-logo" alt="facebook" src={FacebookLogo} />
            </ResolvingLink>
            <ResolvingLink link={twitterLink}>
              <img className="social-logo" alt="twitter" src={TwitterLogo} />
            </ResolvingLink>
          </div>
          {buttonText != null && buttonText !== '' && (
            <ResolvingLink
              link={buttonLink}
              className="mt-12 btn btn-white-outline uppercase"
            >
              {buttonText}
            </ResolvingLink>
          )}
          <span className="pt-6 notera text-white text-4xl hidden md:block">
            Distinctly Okanagan
          </span>
        </div>
        <div className="flex flex-col items-center mt-6 md:items-start md:w-3/5 md:mt-0">
          <h1 className="text-white">{newsletterTitle}</h1>
          <SubscribeForm>
            {(formState, onChange, onSubmit) => {
              const {
                firstName,
                lastName,
                email,
                someField,
                isAgree,
                errors,
                isSubmitting,
                isDone,
              } = formState
              const handleValueChange = valueName => {
                return ({ target }) => {
                  onChange(valueName, target.value)
                }
              }
              return (
                <div className="w-full">
                  {isDone && (
                    <div className="field-bg text-gray-body flex items-center justify-center p-4 mt-4 mx-4 md:mx-0">
                      <p>You've successfully subscribed to our newsletter.</p>
                    </div>
                  )}
                  {errors.length !== 0 && (
                    <div className="field-bg text-gray-body p-4 mt-4 mx-4 md:mx-0">
                      {errors.map((error, idx) => {
                        return <p key={idx}>{error}</p>
                      })}
                    </div>
                  )}
                  {!isDone && (
                    <form className="mt-3 mx-4 md:mx-0" onSubmit={onSubmit}>
                      <div className="flex flex-wrap mb-6">
                        <div className="w-full md:w-1/2 pr-3">
                          <label
                            className="block text-white tracking-wide mb-2 required"
                            htmlFor="FNAME"
                          >
                            {firstNameFieldTitle}
                          </label>
                          <input
                            className="appearance-none block w-full field-bg text-black py-3 px-4 leading-tight focus:outline-none"
                            id="FNAME"
                            name="FNAME"
                            type="text"
                            value={firstName}
                            onChange={handleValueChange('firstName')}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pr-3 mt-6 md:mt-0">
                          <label
                            className="block tracking-wide text-white mb-2"
                            htmlFor="LNAME"
                          >
                            {lastNameFieldTitle}
                          </label>
                          <input
                            className="appearance-none block w-full field-bg text-black py-3 px-4 leading-tight focus:outline-none"
                            id="LNAME"
                            name="LNAME"
                            value={lastName}
                            onChange={handleValueChange('lastName')}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pr-3 mt-6">
                          <label
                            className="block tracking-wide text-white mb-2 required"
                            htmlFor="EMAIL"
                          >
                            {emailFieldTitle}
                          </label>
                          <input
                            className="appearance-none block w-full field-bg text-black py-3 px-4 leading-tight focus:outline-none"
                            id="EMAIL"
                            name="EMAIL"
                            value={email}
                            type="email"
                            onChange={handleValueChange('email')}
                          />
                        </div>
                        <div className="w-full md:w-1/2 pr-3 flex items-center mt-6 md:mt-12">
                          <label className="text-white cursor-pointer">
                            <input
                              className="mr-2 leading-tight"
                              type="checkbox"
                              name="group[11252][1]"
                              id="group[11252]-11252-0"
                              defaultChecked={isAgree}
                              onChange={() => {
                                handleValueChange('isAgree')({
                                  target: { value: !isAgree },
                                })
                              }}
                            />
                            <span className="text-sm select-none">
                              {checkboxText}
                            </span>
                          </label>
                        </div>
                        <div
                          style={{ position: 'absolute', left: '-5000px' }}
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_482c0b12729d579145b0d3da5_a823917954"
                            tabIndex="-1"
                            value={someField}
                            onChange={handleValueChange('someField')}
                          />
                        </div>
                      </div>
                      <SpinnableButton
                        type="submit"
                        isLoading={isSubmitting}
                        className="btn-white-outline"
                      >
                        {signUpButtonText != null && signUpButtonText !== ''
                          ? signUpButtonText
                          : 'Sign Up'}
                      </SpinnableButton>
                    </form>
                  )}
                </div>
              )
            }}
          </SubscribeForm>
          <div className="mx-4 md:mx-0">
            <hr className="border-white border-t-1 w-full mt-6" />
            <span className="text-white text-sm font-light mt-2">
              <RichText
                render={disclaimerRichText}
                htmlSerializer={spanHtmlSerializer}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  )

  if (backgroundFluidImage == null) {
    return (
      <div className={clsx('social-banner-overlay', className)}>
        {socialBannerChildren}
      </div>
    )
  }

  const fluidImgStack = [
    'linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%)',
    'linear-gradient(rgba(217, 154, 44, 0.4) 100%, rgba(217, 154, 44, 0.4) 100%)',
    backgroundFluidImage,
  ]

  return (
    <BackgroundImage Tag="div" fluid={fluidImgStack}>
      {socialBannerChildren}
    </BackgroundImage>
  )
}
