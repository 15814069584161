const DEFAULT_LANGUAGE = 'en-us'

const linkResolver = doc => {
  if (doc == null || doc.type == null || doc.uid == null) {
    return '/'
  }

  if (
    doc.type === 'page' ||
    doc.type === 'generic_page' ||
    doc.type === 'wine_club_page' ||
    doc.type === 'shop_page' ||
    doc.type === 'cart_page' ||
    doc.type === 'calendar_page'
  ) {
    let url =
      doc.lang === DEFAULT_LANGUAGE ? `/${doc.uid}` : `/${doc.lang}/${doc.uid}`

    if (doc.uid === 'home') {
      url = doc.lang === DEFAULT_LANGUAGE ? `/` : `/${doc.lang}/`
    }

    return url
  }

  return '/'
}

module.exports = linkResolver
