import { useState } from 'react'
import toQueryString from 'to-querystring'
import jsonp from 'jsonp'

export default function SubscribeForm({ children }) {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    someField: '',
    isAgreed: false,
    errors: [],
    isSubmitting: false,
    isDone: false,
  })

  const handleOnChange = (name, value) => {
    setFormState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleOnSubmit = e => {
    e.preventDefault()

    const { firstName, lastName, email, someField, isAgree } = formState

    const errors = []

    if (firstName === '') {
      errors.push('First Name cannot be empty')
    }

    if (email === '') {
      errors.push('Email cannot be empty')
    }

    if (!isAgree) {
      errors.push('You must agree to the email subscription terms')
    }

    if (errors.length !== 0) {
      setFormState(prevState => {
        return {
          ...prevState,
          errors,
        }
      })
      return
    }

    const formData = {
      EMAIL: email,
      FNAME: firstName,
      LNAME: lastName,
      [encodeURIComponent('group[11252][1]')]: 'on',
      b_482c0b12729d579145b0d3da5_a823917954: someField,
    }

    const params = toQueryString(formData)

    setFormState(prevState => {
      return {
        ...prevState,
        isSubmitting: true,
      }
    })

    jsonp(
      `https://grizzliwinery.us17.list-manage.com/subscribe/post-json?u=482c0b12729d579145b0d3da5&id=a823917954&${params}`,
      { param: 'c' },
      (err, data) => {
        if (err != null || data == null || data.result !== 'success') {
          setFormState(prevState => {
            return {
              ...prevState,
              isSubmitting: false,
              errors: [
                'There was an error with your request. Please try again later.',
              ],
            }
          })
          return
        }

        setFormState(prevState => {
          return {
            ...prevState,
            isDone: true,
            isSubmitting: false,
            errors: [],
          }
        })
      }
    )
  }

  return children(formState, handleOnChange, handleOnSubmit)
}
