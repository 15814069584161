export const getCurrentLocale = () => {
  const currUrl = window.location
  const currPath = currUrl.pathname

  if (currPath == null) {
    return 'EN'
  }

  const splitPath = currPath.split('/')

  if (splitPath.length < 2) {
    // No info to parse current locale, return english
    return 'EN'
  }

  // Now we know the locale must always be at index 1, since it is always the first element
  // after the first '/'
  const currLocale = splitPath[1]

  // If currLocale isn't zh-cn, then we return English.
  if (currLocale.toLowerCase() === 'zh-cn') {
    return 'CN'
  }

  return 'EN'
}

export const getNewLocalePath = locale => {
  const currUrl = window.location
  const currPath = currUrl.pathname

  if (locale !== 'EN' && locale !== 'CN') {
    return
  }

  if (currPath == null) {
    return
  }

  const currLocale = getCurrentLocale()

  if (currLocale === locale) {
    return
  }

  let newPath = ''

  if (locale === 'CN') {
    newPath = '/zh-cn' + currPath
  } else {
    // We want english
    const splitPath = currPath.split('/')

    if (splitPath.length < 2) {
      // Cannot remove the subpath of locale
      newPath = currPath
    } else {
      // Now we know the locale must always be at index 1, since it is always the first element
      // after the first '/'
      const currLocale = splitPath[1]

      // If currLocale isn't zh-cn, then we cant change it.
      if (currLocale.toLowerCase() !== 'zh-cn') {
        newPath = currPath
      } else {
        // We can change it, remove the first two index of splitPath
        // and join it to create new path.
        const toJoin = [...splitPath]
        toJoin.splice(0, 2)
        newPath = '/' + toJoin.join('/')
      }
    }
  }

  return `${newPath}${window.location.search}${window.location.hash}`
}
