import React from 'react'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import CookieBanner from './CookieBanner'

const PageLayout = function ({ pageData, children }) {
  return (
    <>
      <Navbar pageData={pageData} />
      <main>{children}</main>
      <Footer footerData={pageData} />
      <CookieBanner pageData={pageData} />
    </>
  )
}

export default PageLayout
