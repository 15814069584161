import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { getCurrentLocale, getNewLocalePath } from '../../utils/locale'
import { navigate } from 'gatsby'

export default function LanguageSwitcher() {
  const [currLocale, setCurrLocale] = useState('EN')
  useEffect(() => {
    const currLocale = getCurrentLocale()
    setCurrLocale(currLocale)
  }, [])

  const onChangeLanguage = function (newLang) {
    if (newLang === currLocale) {
      return
    }

    const newPath = getNewLocalePath(newLang)
    setCurrLocale(newLang)
    navigate(newPath)
  }

  const enButtonClassName = clsx(
    'link-btn focus:outline-none',
    currLocale === 'EN' && 'link-btn-active'
  )

  const cnButtonClassName = clsx(
    'link-btn focus:outline-none',
    currLocale === 'CN' && 'link-btn-active'
  )

  return (
    <>
      <button
        className={enButtonClassName}
        onClick={() => onChangeLanguage('EN')}
      >
        EN
      </button>
      <button
        className={cnButtonClassName}
        onClick={() => onChangeLanguage('CN')}
      >
        中
      </button>
    </>
  )
}
