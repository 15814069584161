import React, { useContext } from 'react'
import LoginIcon from './LoginIcon'
import UserContext from '../../context/UserContext'

const shopifyDomainUrl = process.env.GATSBY_SHOPIFY_DOMAIN_LINK

export default function AccountMenuItem({ className }) {
  const { user } = useContext(UserContext)

  if (!user.isLoggedIn) {
    return <a href={`${shopifyDomainUrl}/account/login/`}>LOGIN</a>
  }

  return (
    <a href={`${shopifyDomainUrl}/account/`}>
      <LoginIcon className={className} />
    </a>
  )
}
